
.step-content {
    background: #fff;
    height: calc(100vh - 186px);
    text-align: center;
}
.iconfont {
    color: #20D08C;
    font-size: 60px;
    margin-top: 100px;
    display: inline-block;
}
.text {
    font-size: 24px;
    line-height: 1;
    margin-top: 30px;
}
.operate-btn {
    margin-top: 40px;
}
